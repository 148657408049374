import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["overlay", "form", "query", "results"]

  connect() {
    this.formTarget.addEventListener("submit", (e) => e.preventDefault())

    this.queryTarget.addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    })
  }

  disconnect() {
    this.reset()
  }

  fetchResults() {
    if(this.query == "") {
      this.reset()
      return
    }

    if(this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query


    const url = new URL(this.formTarget.action)
    url.searchParams.append("q", this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()

    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {})
  }

  // VIEW Specific
  openSearch(event) {
    this.overlayTarget.style.display = "block"
    this.queryTarget.focus()
    event.preventDefault();
  }

  closeSearch(event) {
    this.overlayTarget.style.display = "none"
    event.preventDefault();
  }

  // private
  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }
}
